import * as React from "react";
import Swal from "sweetalert2";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ButtonGroup from "@mui/material/ButtonGroup";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Auth } from "aws-amplify";
import Copyright from "./Copyright";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { yellow, green, red } from "@mui/material/colors";
import axios, { Axios } from "axios";
import Alert from "@mui/material/Alert";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const progress = (
  <Box
    hidden={true}
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <CircularProgress />
  </Box>
);

interface User {
  email: string;
}

interface Item {
  cd_pedido_palm: string;
  cd_cliente: string;
  dt_emissao: string;
  vr_pedido: string;
  cd_org_venda: string;
  cd_pedido_cliente: string;
  cd_pedido: string;
  cd_cond_pgto: string;
  cd_tipo_pedido: string;
  dt_entrega: string;
  cd_meio_pgto: string;
  cd_st_pedido: string;
  id_prioridade: string;
  cd_vendedor: string;
  id_tipo_frete: string;
  cd_transportadora: string;
  ds_observacao: string;
  nm_rz_social_ent: string;
  ds_endereco_entr: string;
  nm_cidade_entr: string;
  nm_estado_entr: string;
  nr_cep_entr: string;
  nr_fone_entr: string;
  nr_cnpj_cpf_entr: string;
  nr_cgf_entr: string;
  cd_pedido_palm_pai: string;
  cd_tab_preco: string;
  id_orcamento: string;
  cd_motivo: string;
  cd_produto: string;
  produto: string;
  qt_item: string;
  vr_item: string;
  vr_item_original: string;
  vr_item_minimo: string;
  pc_desconto: string;
  cod_familia: string;
  familiacomercial: string;
  familia: string;
  subfamilia: string;
  canal_venda: string;
}

interface Aprovacao {
  id: number;
  data_criacao: string;
  num_pedido: string;
  email_aprovador: string;
  descricao_motivo_liberacao: string;
  argumento_vendedor: string;
  vendedor: string;
  cliente: string;
  valor_pedido: string;
  itens_pedido: Item[];
  observacao: string;
  situacao: string;
}

function StatusBadge(props: any) {
  const aprovacao = props.aprovacao;

  if (aprovacao.situacao === "Pendente") {
    return (
      <Avatar sx={{ bgcolor: yellow[500] }}>
        {aprovacao.situacao.charAt(0)}
      </Avatar>
    );
  }

  if (aprovacao.situacao === "Aprovado") {
    return (
      <Avatar sx={{ bgcolor: green[500] }}>
        {aprovacao.situacao.charAt(0)}
      </Avatar>
    );
  }

  return (
    <Avatar sx={{ bgcolor: red[500] }}>{aprovacao.situacao.charAt(0)}</Avatar>
  );
}

export default function Pendencias() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState<User>(Object);
  const [aprovacoes, setAprovacoes] = React.useState<Aprovacao[]>([]);
  const [aprovacao, setAprovacao] = React.useState<Aprovacao>(Object);
  const [itens, setItens] = React.useState<Item[]>([]);
  const [totalPendentes, settotalPendentes] = React.useState(0);

  const totItens = (lista_items: Item[]) => {
    return lista_items.reduce((a, b) => a + Number(b.qt_item), 0);
  };

  const VrTotalItens = (lista_items: Item[]) => {
    return lista_items.reduce(
      (a, b) => a + Number(b.vr_item) * Number(b.qt_item),
      0
    );
  };


  const getTipoPedido = (dado: Aprovacao): String => {
      try {
        if (dado.descricao_motivo_liberacao.split(' ')[0] === "Bonificação") {
          return "Bonificação";
        } else {
          return "Venda";
        }
      } catch (err: any) {
        return err.message;
      }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    Swal.fire("Nada foi feito", "", "info");
  };

  async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  const handleReprovarTodosItem = () => {
    setOpen(false);

    var lista = aprovacoes.filter(
      (aprovacao) => aprovacao.situacao == "Pendente"
    );

    lista.map((value) => {
      axios
        .patch(`/aprovacao-sales/api/aprovacao/${value.num_pedido}/reprova/`)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.error(error);
          Swal.fire("Ocorreu um erro ao reprovar o item", "", "error");
        });
    });

    Swal.fire("Reprovados", "", "info");
    getPendencias();
  };

  const handleAprovarTodosItem = () => {
    setOpen(false);

    var lista = aprovacoes.filter(
      (aprovacao) => aprovacao.situacao == "Pendente"
    );

    lista.map((value) => {
      axios
        .patch(`/aprovacao-sales/api/aprovacao/${value.num_pedido}/aprova/`)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.error(error);
          Swal.fire("Ocorreu um erro ao reprovar o item", "", "error");
        });
    });

    Swal.fire("Aprovados", "", "info");
    getPendencias();
  };

  const handleReprovarItem = async () => {
    setOpen(false);
    try {
      const response = await axios.patch(
        `/aprovacao-sales/api/aprovacao/${aprovacao.num_pedido}/reprova/`
      );
      Swal.fire("Reprovado", "", "info");
      getPendencias();
    } catch (error) {
      console.error(error);
      Swal.fire("Ocorreu um erro ao reprovar o item", "", "error");
    }
  };

  const handleAprovarItem = async () => {
    setOpen(false);
    try {
      console.log("vou aprovar o item");
      const response = await axios.patch(
        `/aprovacao-sales/api/aprovacao/${aprovacao.num_pedido}/aprova/`
      );
      Swal.fire("Aprovado", "", "success");
      getPendencias();
    } catch (error) {
      Swal.fire("Ocorreu um erro ao aprovar o item", "", "error");
    }
  };

  const getSupervisor = async (): Promise<string> => {
    let user = await Auth.currentAuthenticatedUser();
    const { attributes } = user;
    let usuario: User = attributes;

    const response_s = await axios.get(
      `/admin-forno/api/substituto/${usuario.email}/email/`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response_s.data.length > 0) {
      return response_s.data[0].principal;
    } else {
      return usuario.email;
    }
  };

  const getPendencias = async () => {
    try {
      let user = await getSupervisor();
      const response = await axios.get(
        `/aprovacao-sales/api/aprovacao/pendente/${user}/email`
      );
      setAprovacoes(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getAprovados = async () => {
    try {
      let user = await getSupervisor();
      const response = await axios.get(
        `/aprovacao-sales/api/aprovacao/liberado/${user}/email`
      );
      setAprovacoes(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getReprovados = async () => {
    try {
      let user = await getSupervisor();
      const response = await axios.get(
        `/aprovacao-sales/api/aprovacao/reprovado/${user}/email`
      );
      setAprovacoes(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getAll = async () => {
    let user = await getSupervisor();
    try {
      const response = await axios.get(
        `/aprovacao-sales/api/aprovacao/${user}/email`
      );

      var lista_pendentes: Aprovacao[] = response.data.filter(
        (dado: Aprovacao) => dado.situacao === "Pendente"
      );

      settotalPendentes(lista_pendentes.length);

      setAprovacoes(lista_pendentes);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCognito = React.useCallback(async () => {
    let user = await Auth.currentAuthenticatedUser();
    const { attributes } = user;
    setUser(attributes);
    await getAll();
  }, []);

  React.useEffect(() => {
    setLoading(true);
    fetchCognito().catch(console.error);
    setLoading(false);
  }, [fetchCognito]);

  const handleAprovar = () => {
    Swal.fire({
      title: "Aprovar todos?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Sim",
      denyButtonText: "Não",
      customClass: {
        actions: "my-actions",
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleAprovarTodosItem();
      } else if (result.isDenied) {
        Swal.fire("Nada foi feito", "", "info");
      }
    });
  };

  const handleReprovar = () => {
    Swal.fire({
      title: "Reprovar todos?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Sim",
      denyButtonText: "Não",
      customClass: {
        actions: "my-actions",
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleReprovarTodosItem();
      } else if (result.isDenied) {
        Swal.fire("Nada foi feito", "", "info");
      }
    });
  };

  const handleSair = () => {
    signOut();
    navigate("/");
  };

  const detalheItem = (value: Aprovacao) => () => {
    setAprovacao(value);
    setItens(value.itens_pedido);
    handleClickOpen();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />

      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Itens
              </Typography>
              <Button
                hidden={aprovacao.situacao !== "Pendente"}
                autoFocus
                color="inherit"
                onClick={handleAprovarItem}
              >
                Aprovar
              </Button>
              <Button
                hidden={aprovacao.situacao !== "Pendente"}
                autoFocus
                color="secondary"
                onClick={handleReprovarItem}
              >
                Reprovar
              </Button>
            </Toolbar>
          </AppBar>
          <Typography variant="h6" component="h3" gutterBottom>
            Quantidade Total: {totItens(itens)}
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom>
            Total:{" "}
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(Number(VrTotalItens(itens)))}
          </Typography>
          <Divider />
          <List
            dense
            sx={{ width: "100%", maxWidth: 800, bgcolor: "background.paper" }}
          >
            {itens.map((value) => {
              const labelId = `checkbox-list-secondary-label-${value}`;
              return (
                <ListItem key={value.cd_produto} disablePadding>
                  <ListItemButton>
                    <ListItemAvatar>
                      <Avatar>
                        <AddShoppingCartIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      id={labelId}
                      secondary={
                        <React.Fragment>
                          <Typography variant="body1" gutterBottom>
                            Quantidade: {value.qt_item} - Valor:{" "}
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(Number(value.vr_item))}
                          </Typography>
                        </React.Fragment>
                      }
                      primary={
                        <React.Fragment>
                          <Typography variant="h6" component="h3" gutterBottom>
                            {value.cd_produto} - {value.produto}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Dialog>
      </div>
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
        <Typography variant="h4" component="h1" gutterBottom>
          Pendências
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          Bem vindo, {user.email}
        </Typography>
        <Alert severity="warning">
          {totalPendentes} pedido(s) pendentes para aprovação.
        </Alert>
        <Divider />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& > *": {
              m: 1,
            },
          }}
        >
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button onClick={getPendencias}>Pendentes</Button>
            <Button onClick={getAprovados}>Aprovados</Button>
            <Button onClick={getReprovados}>Reprovados</Button>
          </ButtonGroup>
        </Box>

        <Fade in={loading}>{progress}</Fade>

        <Stack direction="row" spacing={5} margin={1}>
          <LoadingButton
            loading={loading}
            variant="outlined"
            color="secondary"
            onClick={handleReprovar}
          >
            Reprovar todos
          </LoadingButton>
          <LoadingButton
            loading={loading}
            variant="outlined"
            onClick={handleAprovar}
          >
            Aprovar todos
          </LoadingButton>
          <LoadingButton
            loading={loading}
            variant="outlined"
            onClick={handleSair}
          >
            Sair
          </LoadingButton>
        </Stack>
        <List
          dense
          sx={{ width: "100%", maxWidth: 460, bgcolor: "background.paper" }}
        >
          {aprovacoes.map((value) => {
            return (
              <ListItem
                onClick={detalheItem(value)}
                key={value.id}
                disablePadding
              >
                <ListItemAvatar>
                  <StatusBadge aprovacao={value} />
                </ListItemAvatar>
                <ListItemText
                  primary={`${value.data_criacao} - ${getTipoPedido(value)}`}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {`${value.cliente}`}
                      </Typography>
                      {` - ${value.vendedor} - Motivo: ${value.argumento_vendedor}`}
                    </React.Fragment>
                  }
                />
                <Divider variant="inset" component="li" />
              </ListItem>
            );
          })}
        </List>
      </Container>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="body1">Supervisão Sales.</Typography>

          <Copyright />
        </Container>
      </Box>
    </Box>
  );
}
