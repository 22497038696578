import * as React from 'react';
import Swal from 'sweetalert2';
import Copyright from './Copyright';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import amplify_config from './amplify_config';

Amplify.configure(amplify_config);

const theme = createTheme();

const loading = (
    <Box hidden={true} sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }}>
        <CircularProgress />
    </Box>
);

export default function SignIn() {

    const [checked, setChecked] = React.useState(false);

    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });

        let username: any = '';
        let password: any = '';
        username = data.get('email');
        password = data.get('password');
        setChecked(true);
        username = String(username).trim();

        Auth.signIn({
            username,
            password
        })
            .then((cognitoUser) => {

                if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    const { requiredAttributes } = cognitoUser.challengeParam;
                    console.log('atributos requeridos');
                    console.log(requiredAttributes);
                    Auth.completeNewPassword(
                        cognitoUser,
                        password,
                        {
                            name: username
                        }
                    ).then(user => {
                        setChecked(false);
                        navigate("/pendencias");
                        console.log(user);
                    }).catch(e => {
                        console.log(e);
                    });
                } else {

                    setChecked(false);
                    navigate("/pendencias");

                }
            })
            .catch((err) => {
                setChecked(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err
                });
            });

    };


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Aprovações Sales
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <Fade in={checked}>{loading}</Fade>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Senha"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Entrar
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="https://sales.fornodeminas.com.br" variant="body2">
                                    Esqueceu sua senha?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="https://sales.fornodeminas.com.br" variant="body2">
                                    {"Não tem uma conta? Cadastre-se"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright />
            </Container>
        </ThemeProvider>
    );
}